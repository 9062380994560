@import url('https://fonts.googleapis.com/css2?family=Scheherazade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@300;400;500;700&display=swap');

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;

}

.textContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


/* Gamified Button, like duolingo */
.button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #66a24a;
  border: none;
  border-radius: 50px;
  box-shadow: 0 5px 0 #34682a;
  cursor: pointer;
  transition: all 0.1s ease;
  font-family: 'El Messiri', sans-serif;

}

.button:active {
  box-shadow: none;
  transform: translateY(5px);
}



.teapot {
  position: absolute;
  left: 8%;
  bottom: -4.5%;
  width: 9.4%;
  height: auto;
}



.shadow {
  position: absolute;
  left: 3.5%;
  bottom: -9%;
  width: 27.5%;
  height: auto;
}

.logo {
  max-width: 40%;
  object-fit: contain;
  margin-left: -64%;
  max-height: 100%;
  margin-top: 5%;
}


.logo:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease;
}


.logoFloating {
  animation: floating 2.3s ease infinite;
}

.logoAppearing {
  animation: genieAppearing 2s;
}

/* Genie Positioning for the 3rd page */
.teapotLeft {
  transform: translateX(-70%);
}

/* more complex due to the floating effect overriding the translateX function */
.logoLeft {
  animation: floatingLogoLeft 2.3s ease infinite;
}

@keyframes floatingLogoLeft {
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  50% {
    transform: translate3d(-10%, -10px, 0);
  }

  100% {
    transform: translate3d(-10%, 0, 0);
  }
}


.shadowLeft {
  transform: translateX(-15%);
}

@keyframes genieAppearing {
  0% {
    transform: translateX(-100%) translateY(70%) scale(0.2);
    opacity: 0;
  }

  50% {
    transform: translateX(0) translateY(0) scale(1.2);
    opacity: 1;
  }

  100% {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
}


@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}







.enterButtonContainer {
  text-align: center;
  position: relative;
}

.enterButton {
  font-size: 30px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #D4AF37;
  border-radius: 25px;
  border: 2px solid #205a1d;
  color: #205a1d;
  font-family: 'Scheherazade', serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  position: relative;
  z-index: 1;
}

.enterButton:hover {
  background-color: #205a1d;
  border-color: #D4AF37;
  color: #D4AF37;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.sparkle {
  background: gold;
  height: 20%;
  width: 1em;
  border-radius: 0.25em;
  transform: rotate(45deg);
  position: absolute;
  opacity: 0;
  animation: sparkleEffect 2s infinite;
}

.sparkle:nth-child(1) {
  animation-delay: 0s;
}

.sparkle:nth-child(2) {
  animation-delay: 0.2s;
}

.sparkle:nth-child(3) {
  animation-delay: 0.4s;
}

.sparkle:nth-child(4) {
  animation-delay: 0.6s;
}

.sparkle:nth-child(5) {
  animation-delay: 0.8s;
}

.sparkle:nth-child(6) {
  animation-delay: 1s;
}

.sparkle:nth-child(7) {
  animation-delay: 1.2s;
}

.sparkle:nth-child(8) {
  animation-delay: 1.35s;
}

.sparkle::before,
.sparkle::after {
  content: '';
  position: absolute;
  background: inherit;
  top: -0.25em;
  right: -0.75em;
  height: 0.5em;
  width: 0.5em;
  border-radius: 0.15em;
}

.sparkle::after {
  top: 0.6em;
  right: -0.65em;
  height: 0.3em;
  width: 0.3em;
  border-radius: 0.1em;
}

.enterButton:hover~.sparkle {
  opacity: 1;
}

.enterButton:hover~.sparkle:nth-child(1) {
  top: -0%;
  left: 10%;
}

.enterButton:hover~.sparkle:nth-child(2) {
  top: -30%;
  left: 70%;
}

.enterButton:hover~.sparkle:nth-child(3) {
  top: 50%;
  left: -30%;
}

.enterButton:hover~.sparkle:nth-child(4) {
  top: 50%;
  left: 130%;
}

.enterButton:hover~.sparkle:nth-child(5) {
  top: 130%;
  left: 25%;
}

.enterButton:hover~.sparkle:nth-child(6) {
  top: 130%;
  left: 85%;
}


.enterButton:hover~.sparkle:nth-child(7) {
  top: 130%;
  left: 85%;
}


.enterButton:hover~.sparkle:nth-child(8) {
  top: 100%;
  left: 35%;
}

@keyframes sparkleEffect {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.speakerIconContainer {
  position: fixed;
  top: 1%;
  right: 0.25%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #c81f47;
  border-radius: 50%;
  border: 2px solid #f9c603;
}


.speakerIcon {
  font-size: 100%;
  cursor: pointer;
  color: #f9c603;
}



.volumeslider {
  position: fixed;
  top: 5.1%;
  right: 0.25%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: gold;
  border-radius: 50%;
  color: gold;

}


/* Genie Positioning for the 3rd page */
.teapotLeft {
  transform: translateX(-70%);
}

/* more complex due to the floating effect overriding the translateX function */
.logoLeft {
  animation: floatingLogoLeft 2.3s ease infinite;
}

@keyframes floatingLogoLeft {
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  50% {
    transform: translate3d(-10%, -10px, 0);
  }

  100% {
    transform: translate3d(-10%, 0, 0);
  }
}


.shadowLeft {
  transform: translateX(-15%);
}


/* Footer */
.footer {
  position: fixed;
  bottom: 2px;
  text-align: center;
  font-size: 11.8px;
  font-family: ' Saira Stencil One', cursive;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);

}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}