@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@300;400;500;700&display=swap');

.backgroundHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 102.5%;
  background-image: url('./assets/Background1.png');
  filter: opacity(1);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -2; 
  
}


.buttonContainerIntroPage {
  display: flex;
  justify-content: center;
  align-items: center; 
  position: fixed;
  left: -1%; 
  right: -80%;
  bottom: 3%;
  padding: 0 16px;
}

 

.greetingBubble {
  margin: 0;
  padding: 10px;
  background: #CBF4BC;
  border-radius: 0 5px 5px 5px;
  position: absolute;
  font-size: 4rem;
  color: #254B16;
  font-family: 'El Messiri', sans-serif;
  right: 37%;
  top: calc(50% - 200px); /* Update the positioning here */
}

.greetingBubble::before {
  content: '';
  position: absolute;
  bottom: -13px;
  left: 10px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 20px solid #CBF4BC;
  border-bottom: 0 solid transparent;
  border-left: 13px solid transparent;
  transform: rotate(-180deg);
}

.greetingBubble::after {
  content: '';
  font-size: 1rem;
  position: absolute;
  top: 2px;
  right: 4px;
  color: #666;
}


.titlear {
  color: #c92933;
  font-size: 5rem;
  font-weight: bold;
  font-family: 'Scheherazade', serif;
  position: absolute;
  top: 43%;
  left: 53%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 3px rgba(255, 215, 0, 0.5), 2px 2px 6px rgba(255, 215, 0, 0.5);
  text-align: right; /* Align text to the right */
}

.subtitlear {
  color: #8f1e15;
  font-size: 2.3rem;
  position: absolute;
  top: 62%;
  left: 53%;
  font-family: 'El Messiri', sans-serif;
  transform: translate(-50%, -50%);
  direction: rtl; /* Ensure text is right-to-left */
  unicode-bidi: bidi-override; /* Override text direction */
  text-align: right; /* Align text to the right */
}


.titlefr {
  color: #c92933;
  font-size: 3.70rem;
  font-weight: bold;
  font-family: 'El Messiri', sans-serif;
  line-height: 90%; 
  position: absolute;
  top: 48%;
  left: 52.2%;  
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 3px rgba(255, 215, 0, 0.5), 2px 2px 6px rgba(255, 215, 0, 0.5);
}


.subtitlefr {
  color: #882331;
  font-size: 2.2rem;
  font-family: 'El Messiri', sans-serif;
  position: absolute;
  top: 64%;
  left: 53%;
  line-height: 90%;
  position: absolute;
  white-space: pre-line;
  transform: translate(-50%, -50%);
}

.titleeng {
    color: #c92933;
    font-size: 4rem;
    font-weight: bold;
    font-family: 'El Messiri', sans-serif;
    line-height: 90%; 
    position: absolute;
    top: 48%;
    left: 52.2%;  
    transform: translate(-50%, -50%);
    text-shadow: 1px 1px 3px rgba(255, 215, 0, 0.5), 2px 2px 6px rgba(255, 215, 0, 0.5);
}

.subtitleeng {
  color: #882331;
  font-size: 2.3rem;
  font-family: 'El Messiri', sans-serif;
  position: absolute;
  top: 65%;
  left: 54%;
  line-height: 90%; 
  position: absolute;
  white-space: pre-line;
  transform: translate(-50%, -50%);
}


.titledarija {
  color: #c92933;
  font-size: 4.5rem;
  font-weight: bold;
  font-family: 'El Messiri', sans-serif;
  position: absolute;
  top: 49%;
  left: 52%; 
  line-height: 90%; 
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 3px rgba(255, 215, 0, 0.5), 2px 2px 6px rgba(255, 215, 0, 0.5);
}

.subtitledarija {
  color: #882331;
  font-size: 2.3rem;
  font-family: 'El Messiri', sans-serif;
  position: absolute;
  top: 68%;
  left: 52%;
  line-height: 90%; 
  position: absolute;
  white-space: pre-line;
  transform: translate(-50%, -50%);
}

/* Responsive styles */

/* Small phones (Galaxy Fold, iPhone 5/SE) */
@media (max-width: 320px) {
  .greetingBubble {
    font-size: 1.2rem;
    right: 5%;
    top: 10%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 2rem;
    top: 20%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 1rem;
    top: 40%;
  }
  .buttonContainerIntroPage {
    left: 0;
    right: 0;
    bottom: 10%;
    padding: 0 8px;
  }
}

/* Medium phones (iPhone 6/7/8, Pixel 2) */
@media (min-width: 321px) and (max-width: 375px) {
  .greetingBubble {
    font-size: 1.4rem;
    right: 10%;
    top: 12%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 2.2rem;
    top: 25%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 1.2rem;
    top: 45%;
  }
  .buttonContainerIntroPage {
    left: 0;
    right: 0;
    bottom: 8%;
    padding: 0 10px;
  }
}

/* Large phones (iPhone X, Pixel 2 XL) */
@media (min-width: 376px) and (max-width: 414px) {
  .greetingBubble {
    font-size: 1.6rem;
    right: 15%;
    top: 15%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 2.5rem;
    top: 30%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 1.4rem;
    top: 50%;
  }
  .buttonContainerIntroPage {
    left: 0;
    right: 0;
    bottom: 6%;
    padding: 0 12px;
  }
}

/* Small tablets (iPad Mini, Nexus 7) */
@media (min-width: 415px) and (max-width: 768px) {
  .greetingBubble {
    font-size: 2rem;
    right: 20%;
    top: 20%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 3rem;
    top: 35%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 1.6rem;
    top: 55%;
  }
  .buttonContainerIntroPage {
    left: 0;
    right: 0;
    bottom: 5%;
    padding: 0 14px;
  }
}

/* Large tablets (iPad, iPad Pro) */
@media (min-width: 769px) and (max-width: 1024px) {
  .greetingBubble {
    font-size: 3rem;
    right: 20%;
    top: 10%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 5rem;
    top: 30%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 2.5rem;
    top: 55%;
  }
}

/* Small laptops and large tablets in landscape */
@media (min-width: 1025px) and (max-width: 1368px) {
  .greetingBubble {
    font-size: 3rem;
    right: 35%;
    top: 20%;
  }
  .titlear, .titlefr, .titleeng, .titledarija {
    font-size: 4rem;
    top: 40%;
  }
  .subtitlear, .subtitlefr, .subtitleeng, .subtitledarija {
    font-size: 2.2rem;
    top: 60%;
  }
}

/* Adjust the button container for all screen sizes */
@media (max-width: 1024px) {
  .buttonContainerIntroPage {
    left: 0;
    right: 0;
    bottom: 5%;
    padding: 0 8px;
  }
}

/* Responsive styles for tables and papyrus */
@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
  }

  th, td {
    padding: 6px;
    text-align: left;
    border: 1px solid #ddd;
  }

  /* Make the table more compact */
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  /* Adjust papyrus size for smaller screens */
  .papyrus {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }

  /* Adjust font sizes for table content */
  .table-container {
    font-size: 12px;
  }

  /* Optional: Hide less important columns on very small screens */
  @media (max-width: 480px) {
    .hide-on-mobile {
      display: none;
    }
  }
}

/* Existing media queries... */