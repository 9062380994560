.globeIcon {
  font-size: 23px;
  color: #f9c603;
  margin-right: 8px;
}

.container {
  position: fixed;
  top: 1%;
  left: 0.7%;
  display: flex;
  align-items: center;
  z-index: 9999; /* set a high z-index value */
}

.languageSelect {
  appearance: none;
  outline: none;
  border: 1px solid #f9c603;
  padding: 8px 12px;
  font-size: 18px;
  font-weight: bold;
  color: #f9c603;
  background-color: #c81f47;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.languageSelect:hover {
  border-color: #f9c603;
  color: #f9c603;
}

.languageSelect:active {
  background-color: #c81f47;
}
